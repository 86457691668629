import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

dayjs.extend(relativeTime);

// Exporting plugins makes the types being available in the webapp
export { dayjs, utc, relativeTime };

import { z } from 'zod';

import type { postQuestOutputSchema } from './postQuest';

export const patchReorderQuestsEntitySchema = z.object({
  id: z.string(),
  categoryId: z.string(),
});

export const patchReorderQuestsInputSchema = z.array(patchReorderQuestsEntitySchema);

// PATCH /quests/v2/
export type PatchReorderQuestsInput = z.infer<typeof patchReorderQuestsInputSchema>;
export type PatchReorderQuestsOutput = z.infer<typeof postQuestOutputSchema>;

import { z } from 'zod';

import { discordIntegrationTypeSchema } from './integrationType';

export const integrationConfigSchema = z.object({
  active: z.boolean(),
  channelId: z.string(),
  mentionId: z.string().optional(),
});

export type IntegrationConfig = z.infer<typeof integrationConfigSchema>;

export const postDiscordIntegrationInputSchema = z.record(
  discordIntegrationTypeSchema,
  integrationConfigSchema,
);

export type PostDiscordIntegrationInput = z.infer<typeof postDiscordIntegrationInputSchema>;

import { z } from 'zod';

export const getQuestResultOutputSchema = z.object({
  submissions: z.number(),
  success: z.number(),
  successRate: z.number(),
});

// GET /communities/:subdomain/quests/:id/result
export type GetQuestResultOutput = z.infer<typeof getQuestResultOutputSchema>;

export const authErrorNames = [
  'invalid_auth',
  'already_exists',
  'already_connected',
  'human_check_failed',
  'user_not_found',
  'wallet_login_deprecated',
] as const;

export type AuthErrorName = (typeof authErrorNames)[number];

export const isAuthError = (error: Error): boolean => {
  return error instanceof AuthError || authErrorNames.includes(error.name as AuthErrorName);
};

export interface IAuthError {
  code?: number;
  name: AuthErrorName;
  message?: string;
  data: {
    accountType?: 'discord' | 'twitter' | 'wallet' | 'email' | 'tiktok';
    userId?: string;
  };
}

export interface IAlreadyConnectedError extends IAuthError {
  data: {
    accountType?: 'discord' | 'twitter' | 'wallet' | 'email' | 'tiktok';
    userId: string;
    userAccountId: string;
    jwtToken: string;
  };
}

/**
 * These errors will be returned from the auth router.
 * For the oauth methods (twitter, discord, etc), the error will be returned as a query string in the redirect url.
 * For the basic auth methods (email, wallet, etc), the error will be returned as a json object in the response body.
 * @example `https://zealy.io?error=already_connected&accountType=discord&userId=123`
 *
 * @example ```json
 * {
 *  "name": "already_connected",
 *  "data": {
 *      "accountType": "discord",
 *      "userId": "123"
 *  }
 * }
 * ```
 */
export class AuthError extends Error {
  public name: AuthErrorName;
  public code: number;
  public data: {
    accountType?: IAuthError['data']['accountType'];
    userId?: string;
    date?: string;
    jwtToken?: string;
    email?: string;
  };

  constructor({ name, message, data, code }: Partial<IAuthError>) {
    super();
    this.code = code || 401;
    this.name = name || 'invalid_auth';
    this.message = message || 'Invalid authentication';
    this.data = data || {};
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

/**
 * User already has an account of this type
 */
export class AlreadyExistsError extends AuthError {
  constructor(accountType: IAuthError['data']['accountType']) {
    super({
      code: 422,
      name: 'already_exists',
      data: {
        accountType,
      },
      message: 'Already has an account of this type',
    });
    Object.setPrototypeOf(this, AlreadyExistsError.prototype);
  }
}

/**
 * Account is already connected to another user
 */
export class AlreadyConnectedError extends AuthError {
  public data: {
    accountType: IAlreadyConnectedError['data']['accountType'];
    userId: string;
    userAccountId: string;
    jwtToken: string;
  };

  constructor(
    accountType: IAlreadyConnectedError['data']['accountType'],
    userId: string,
    userAccountId: string,
    jwtToken: string,
  ) {
    super({
      code: 422,
      name: 'already_connected',
      message: `This ${accountType} account is already connected to a different user`,
    });
    this.data = {
      userAccountId,
      userId,
      jwtToken,
      accountType,
    };
    Object.setPrototypeOf(this, AlreadyConnectedError.prototype);
  }
}

/**
 * Failed ReCaptcha verification
 */
export class HumanCheckFailedError extends AuthError {
  constructor() {
    super({
      code: 403,
      name: 'human_check_failed',
      message:
        "Unable to confirm you're human. If you are using a VPN, change your location or disable it, refresh the page, and try again.",
    });

    Object.setPrototypeOf(this, HumanCheckFailedError.prototype);
  }
}

// throw new Auth.AlreadyConnectedError('twitter', '123');
export const AuthErrors = {
  AlreadyExistsError,
  AlreadyConnectedError,
  HumanCheckFailedError,
};

export const errorToQueryParams = (error: unknown) => {
  const errorString = JSON.stringify(error);
  return `${encodeURIComponent(errorString)}`;
};

export const queryParamsToError = (queryParams: string) => {
  const res = new URLSearchParams(queryParams);

  const error = res.get('error');

  return error ? JSON.parse(error) : undefined;
};

export const extractErrorMessage = (body: unknown) => {
  if (body && typeof body === 'object' && 'message' in body && typeof body.message === 'string') {
    return body.message;
  }
  return undefined;
};

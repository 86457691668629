import { z } from 'zod';

import { descriptionSchema } from '../v2/common';

const getSprintsInputSchema = z.object({
  query: z
    .object({
      onlyCurrent: z.coerce.boolean().default(false).optional(),
    })
    .optional(),
});

export const sprintSchema = z.object({
  id: z.string(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  endingAt: z.string().min(1),
  rewards: z.union([descriptionSchema, z.object({})]).nullable(),
  rewardZone: z
    .number()
    .or(z.nan().transform(() => null))
    .nullish(),
  includedQuestIds: z.array(z.string()).optional(),
  startingAt: z.string().min(1),
  name: z.string().optional().nullable(),
  description: z.string().max(3000).optional().nullable(),
  color: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
});

export type GetSprintsInput = z.infer<typeof getSprintsInputSchema>;

import { z } from 'zod';

import { additionalQuestCheck, postQuestBaseInputSchema, postQuestOutputSchema } from './postQuest';

export const patchQuestsInputSchema = additionalQuestCheck(
  postQuestBaseInputSchema.partial().extend({
    questsIds: z.array(z.string()),
    deleted: z.boolean().optional(),
    archived: z.boolean().optional(),
  }),
);

export const patchQuestsOutputSchema = z.array(postQuestOutputSchema);

// PATCH /quests/v2/:id
export type PatchQuestsInput = z.infer<typeof patchQuestsInputSchema>;
export type PatchQuestsOutput = z.infer<typeof patchQuestsOutputSchema>;

import { z } from 'zod';

export const moduleSchema = z.object({
  id: z.string().uuid(),
  communityId: z.string().uuid(),
  name: z.string().max(100),
  description: z.string().max(300).optional(),
  coverUrl: z.union([z.literal('').optional(), z.string().trim().url()]),
  color: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deleted: z.boolean().default(false),
  position: z.number().optional(),
});

export type ModuleSchemaType = z.infer<typeof moduleSchema>;

export const updateModuleInputSchema = moduleSchema.partial();

export type UpdateModuleInputSchemaType = z.infer<typeof updateModuleInputSchema>;

export const createModuleInputSchema = moduleSchema.pick({
  name: true,
  description: true,
  coverUrl: true,
  color: true,
  position: true,
});

export type CreateModuleInputSchemaType = z.infer<typeof createModuleInputSchema>;

export const patchReorderModulesInputSchema = z.object({
  moduleIds: z.array(z.string()),
});

// PATCH /reorder modules
export type PatchReorderModulesInput = z.infer<typeof patchReorderModulesInputSchema>;
export type PatchReorderModulesOutput = z.infer<typeof updateModuleInputSchema>;

import { z } from 'zod';

export const getTaskResultInputSchema = z.object({
  page: z
    .number({
      coerce: true,
    })
    .min(1)
    .optional()
    .default(1),
  limit: z
    .number({
      coerce: true,
    })
    .min(1)
    .optional()
    .default(30),
});

export const getTaskResultOutputSchema = z.array(
  z.object({
    user: z.object({
      id: z.string().uuid(),
      name: z.string(),
      avatar: z.string().url(),
    }),
    status: z.enum(['success', 'error', 'in-review']),
  }),
);

const taskTypeValue = {
  poll: z.array(z.string()),
  quiz: z.array(z.string()),
  text: z.string(),
  file: z.array(z.string().url()),
  opinion: z.number(),
};

const generateTaskResultOutputSchema = <ItemType extends keyof typeof taskTypeValue>(
  taskType: ItemType,
) =>
  z.array(
    z.object({
      user: z.object({
        id: z.string().uuid(),
        name: z.string(),
        avatar: z.string().url(),
      }),
      status: z.enum(['success', 'error', 'in-review']),
      value: taskTypeValue[taskType],
      createdAt: z.string().datetime().optional(),
    }),
  );

// GET /communities/:subdomain/quests/:questId/tasks/:id/result
type AggregatedResultTaskType = ['poll', 'quiz', 'opinion'];
export type GetTaskResultOutput<T extends keyof typeof taskTypeValue> =
  T extends AggregatedResultTaskType[number]
    ? {
        answer: string | number;
        occurrence: number;
        percentage: number;
      }[]
    : z.infer<ReturnType<typeof generateTaskResultOutputSchema<T>>>;

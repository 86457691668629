import { z } from 'zod';

import { baseQuestSchema } from './admin';

const baseModuleSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  coverUrl: z.string().url().optional(),
  color: z.string().optional(),
  totalQuestCount: z.number(),
  quests: z.array(baseQuestSchema),
});

export const questAdminFiltersFieldSchema = z.discriminatedUnion('f', [
  z.object({
    f: z.literal('status'),
    v: z.array(z.enum(['draft', 'published', 'archived', 'expired', 'active', 'scheduled'])),
  }),
  z.object({
    f: z.literal('recurrence'),
    v: z.array(z.enum(['once', 'daily', 'weekly', 'monthly'])),
  }),
  z.object({
    f: z.literal('cooldown'),
    v: z.array(
      z.enum(['immediately', '1min', '5min', '30min', '1hour', '1day', '1week', '1month', 'never']),
    ),
  }),
  z.object({
    f: z.literal('reward'),
    v: z.array(z.enum(['xp', 'role', 'nft', 'custom'])),
  }),
  z.object({
    f: z.literal('prerequisite'),
    v: z.array(z.enum(['level', 'role', 'nft', 'quest', 'date'])),
  }),
]);

export const questAdminFiltersSchema = questAdminFiltersFieldSchema.and(
  z.object({
    c: z.enum(['IS', 'NOT']),
    n: z.enum(['AND', 'OR']),
  }),
);

export type QuestAdminFilters = z.infer<typeof questAdminFiltersSchema>;

export const getAdminQuestboardOutputSchema = z.array(baseModuleSchema);
export const getAdminQuestboardInputSchema = z.object({
  filters: z.array(questAdminFiltersSchema).optional(),
});

// GET /admin/questboard (return a list of categories with metadata about quests associated to the category). Being an admin endpoint, it doesn't return the data associated with the status of each quest or category.
export type GetAdminQuestboardInput = z.infer<typeof getAdminQuestboardInputSchema>;
export type GetAdminQuestboardOutput = z.infer<typeof getAdminQuestboardOutputSchema>;

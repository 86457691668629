import WAValidator from 'multicoin-address-validator';

import { match } from './patterns';

export type ValidatedBlockchain =
  | 'ethereum'
  | 'polygon'
  | 'avalanche'
  | 'binance smart chain'
  | 'solana'
  | 'arweave'
  | 'cardano';

export const validateAddress = (blockchain: string, address: string) => {
  try {
    return match<ValidatedBlockchain, boolean>(blockchain as ValidatedBlockchain, {
      ethereum: WAValidator.validate(address, 'eth'),
      polygon: WAValidator.validate(address, 'eth'),
      avalanche: WAValidator.validate(address, 'eth'),
      'binance smart chain': WAValidator.validate(address, 'bnb'),
      solana: WAValidator.validate(address, 'sol'),
      arweave: address.length === 43,
      cardano: WAValidator.validate(address, 'ada'),
    });
  } catch (e) {
    // test for whitespace
    return !/\s/.test(address);
  }
};

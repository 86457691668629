const roleAccessLevel = {
  admin: 3,
  editor: 2,
  reviewer: 1,
  guest: 0,
  banned: -1,
};

export type UserRole = keyof typeof roleAccessLevel;

export const roleIsAtLeast = (role: UserRole | null | undefined, minimumRole: UserRole) => {
  if (!role) {
    return false;
  }
  return roleAccessLevel[role] >= roleAccessLevel[minimumRole];
};

import { z } from 'zod';

export const getRankInputSchema = z.object({
  query: z
    .object({
      sprintId: z.string().uuid().optional(),
    })
    .optional(),
});

export const getRankOutputSchema = z.object({
  avatar: z.string().url().nullable(),
  id: z.string(),
  name: z.string(),
  rank: z.number(),
  xp: z.number(),
  numberOfQuests: z.number(),
});

export type GetRankInput = z.infer<typeof getRankInputSchema>;

export type GetRankOutput = z.infer<typeof getRankOutputSchema>;

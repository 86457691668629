import { z } from 'zod';

import { integrationConfigSchema } from './updateIntegration';

const missingPermissionSchema = z.enum(['MISSING_ACCESS', 'SEND_MESSAGES', 'EMBED_LINKS']);

export type MissingPermissions = z.infer<typeof missingPermissionSchema>;

const integrationWithMissingPermissionsSchema = integrationConfigSchema.extend({
  missingPermission: missingPermissionSchema,
});

export const supportConfigSchema = z.object({
  active: z.boolean(),
  channelId: z.string(),
  missingPermission: missingPermissionSchema,
});

const webhookDataSchema = z.object({
  guildId: z.string(),
  permissions: z.number(),
  newClaim: integrationWithMissingPermissionsSchema.optional(),
  newQuest: integrationWithMissingPermissionsSchema.optional(),
  dailySummary: integrationWithMissingPermissionsSchema.optional(),
  leaderboardSprintEnd: integrationWithMissingPermissionsSchema.optional(),
  support: supportConfigSchema,
});

const channelSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const roleSchema = z.object({
  id: z.string(),
  name: z.string(),
  position: z.number(),
  permissions: z.string(),
  editable: z.boolean(),
});

export const getGuildOutputSchema = webhookDataSchema.extend({
  id: z.string(),
  name: z.string(),
  channels: z.array(channelSchema),
  roles: z.array(roleSchema),
});

export type GetGuildOutput = z.infer<typeof getGuildOutputSchema>;

import { z } from 'zod';

export const uploadFileInputSchema = z.object({
  communityId: z.string().uuid().optional(),
  subdomain: z.string().optional(),
});

export const uploadFileOutputSchema = z.object({
  url: z.string(),
});

export type UploadFileInput = z.infer<typeof uploadFileInputSchema>;

export type UploadFileOutput = z.infer<typeof uploadFileOutputSchema>;

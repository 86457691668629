import { z } from 'zod';

const getCRMInputSchema = z.object({
  path: z.object({
    subdomain: z.string(),
  }),

  query: z.object({
    page: z.number(),
    limit: z.number(),
    search: z.string().optional(),
    sortBy: z.string(),
    orderBy: z.string(),
  }),
});

const CommunityMember = z.object({
  id: z.string(),
  name: z.string(),
  avatar: z.string(),
  role: z.enum(['guest', 'editor', 'admin', 'reviewer', 'banned']).nullable(),
  communityId: z.string(),
  userId: z.string(),
  xp: z.union([z.string(), z.number()]),
  country: z.string().optional(),
  city: z.string().optional(),
  discordId: z.string().nullable(),
  twitterId: z.string().nullable(),
  email: z.string().nullable(),
  tiktokUsername: z.string().nullable(),
  twitterUsername: z.string().nullable(),
  twitterFollowersCount: z.number(),
  discordHandle: z.string().nullable(),
  address: z.string().nullable(),
  ethAddress: z.string().nullable(),
  joinDate: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  socialAccounts: z.array(
    z.object({ id: z.string(), name: z.string(), type: z.string(), verified: z.boolean() }),
  ),
  discordServers: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      icon: z.string(),
      owner: z.boolean(),
    }),
  ),
  metadata: z.record(z.union([z.string(), z.number()])),
  invites: z.number(),
});

const getCRMOutputSchema = z.object({
  members: z.array(CommunityMember),
  totalCountPages: z.number(),
  metadataFields: z.record(
    z.union([z.literal('number'), z.literal('string'), z.literal('date'), z.literal('select')]),
  ),
});

export type GetCRMInput = z.infer<typeof getCRMInputSchema>;

export type GetCRMOutput = z.infer<typeof getCRMOutputSchema>;

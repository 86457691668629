import { z } from 'zod';

export const discordGuildMissingPermissionEventSchema = z.object({
  guildId: z.string(),
  missingPermission: z.enum(['SEND_MESSAGES', 'MANAGE_ROLES', 'EMBED_LINKS', 'MISSING_ACCESS']),
  additionalInfo: z
    .object({
      channelId: z.string().optional(),
    })
    .optional(),
});

export type DiscordGuildMissingPermissionEvent = z.infer<
  typeof discordGuildMissingPermissionEventSchema
>;

import dayjs from 'dayjs';

export const TWEET_URL_META_REGEX =
  /(:?https:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/([a-zA-Z0-9_]+)\/status\/([0-9]+)/g;

export const getTweetMetadataFromUrl = (
  tweetUrl: string | undefined,
): {
  username: string | undefined;
  tweetId: string | undefined;
} => {
  const [result] = Array.from((tweetUrl ?? '').matchAll(TWEET_URL_META_REGEX), m => [m[3], m[4]]);
  const [username, tweetId] = result || [];
  return {
    username,
    tweetId,
  };
};

export function getTweetIdFromUrl(tweetUrl?: string): string | undefined {
  const { tweetId } = getTweetMetadataFromUrl(tweetUrl);
  return tweetId;
}

export const isTweetUrlValid = (tweetUrl: string) => {
  return !!tweetUrl.match(TWEET_URL_META_REGEX);
};

// This function is used to validate a tweet ID based on https://en.wikipedia.org/wiki/Snowflake_ID
export const isTweetIdInvalid = (
  tweetId: string,
  questCreationDate: string,
): false | 'TWEET_NOT_FOUND' | 'TWEET_CREATED_BEFORE_QUEST' => {
  // Check if the tweetId is numeric and its length suggests it's likely a 64-bit integer
  if (!/^\d+$/.test(tweetId)) {
    return false;
  }

  const tweetIdNum = BigInt(tweetId);

  // 64-bit maximum and minimum checks
  if (tweetIdNum > BigInt('0xFFFFFFFFFFFFFFFF') || tweetIdNum < BigInt(0)) {
    return 'TWEET_NOT_FOUND';
  }

  // Twitter's custom epoch for Snowflake IDs
  const twitterEpoch = BigInt(1288834974657);

  // Shift right to discard the machine ID and sequence number and extract the time since the custom epoch
  const timeSinceTwitterEpoch = tweetIdNum >> BigInt(22); // Right shift by 22 bits to remove the 10+12 bits of machine ID and sequence number

  // Convert to actual timestamp (milliseconds)
  const tweetTimestamp = timeSinceTwitterEpoch + twitterEpoch;

  // Get current time in milliseconds
  const now = BigInt(dayjs.utc().valueOf());

  const questCreationDateTimestamp = BigInt(dayjs.utc(questCreationDate).valueOf());

  const tweetIsCreatedAfterTwitterEpoch = tweetTimestamp > twitterEpoch;
  const tweetIsCreatedBeforeNow = tweetTimestamp <= now;
  const tweetIsCreatedAfterQuestCreation = tweetTimestamp >= questCreationDateTimestamp;

  if (
    tweetIsCreatedAfterTwitterEpoch &&
    tweetIsCreatedBeforeNow &&
    tweetIsCreatedAfterQuestCreation
  ) {
    return false;
  }

  if (!tweetIsCreatedAfterQuestCreation) {
    return 'TWEET_CREATED_BEFORE_QUEST';
  }

  return 'TWEET_NOT_FOUND';
};

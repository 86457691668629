import bs58 from 'bs58';
import nacl from 'tweetnacl';
import { z } from 'zod';

/**
 * Possible message error types.
 */
export enum SiwsErrorType {
  /** `expirationTime` is present and in the past. */
  EXPIRED_MESSAGE = 'Expired message.',

  /** `nonce` don't match the nonce provided for verification. */
  NONCE_MISMATCH = 'Nonce does not match provided nonce for verification.',

  /** `address` does not conform to EIP-55 or is not a valid address. */
  INVALID_ADDRESS = 'Invalid address.',

  /** `uri` does not conform to RFC 3986. */
  INVALID_URI = 'URI does not conform to RFC 3986.',

  /** `nonce` is smaller then 8 characters or is not alphanumeric */
  INVALID_NONCE = 'Nonce size smaller then 8 characters or is not alphanumeric.',

  /** Signature doesn't match the address of the message. */
  INVALID_SIGNATURE = 'Signature does not match address of the message.',

  /** `expirationTime`, `notBefore` or `issuedAt` not complient to ISO-8601. */
  INVALID_TIME_FORMAT = 'Invalid time format.',

  /** `version` is not 1. */
  INVALID_MESSAGE_VERSION = 'Invalid message version.',

  /** Thrown when some required field is missing. */
  UNABLE_TO_PARSE = 'Unable to parse the message.',
}

const SiwsSchema = z.object({
  domain: z.string(),
  address: z.string(),
  statement: z.string(),
  uri: z.string(),
  version: z.string(),
  nonce: z.string(),
  params: z.string().optional(),
  issuedAt: z.string().optional(),
  expirationTime: z.string().optional(),
  chainId: z.number().optional(),
});

export class SiwsMessage {
  domain: string;
  address: string;
  statement?: string;
  uri: string;
  version: string;
  nonce: string;
  issuedAt?: string;
  expirationTime?: string;

  constructor(
    params:
      | {
          domain: string;
          address: string;
          statement?: string;
          uri: string;
          version: string;
          nonce: string;
          params?: string;
          issuedAt?: string;
          expirationTime?: string;
        }
      | string,
  ) {
    if (typeof params === 'string') {
      const parsed = JSON.parse(params);
      this.domain = parsed.domain;
      this.address = parsed.address;
      this.statement = parsed.statement;
      this.uri = parsed.uri;
      this.version = parsed.version;
      this.nonce = parsed.nonce;
      this.issuedAt = parsed.issuedAt;
      this.expirationTime = parsed.expirationTime;
    } else {
      this.domain = params.domain;
      this.address = params.address;
      this.statement = params.statement;
      this.uri = params.uri;
      this.version = params.version;
      this.nonce = params.nonce;
      this.issuedAt = params.issuedAt ?? new Date().toUTCString();
      this.expirationTime = params.expirationTime;
    }

    const result = SiwsSchema.safeParse(this);
    if (!result.success) {
      throw new Error(SiwsErrorType.UNABLE_TO_PARSE);
    }
  }

  prepareMessage() {
    const headerPrefx = this.domain;
    const header = `${headerPrefx} wants you to sign in with your Solana account:`;
    const uriField = `URI: ${this.uri}`;
    let prefix = [header, this.address].join('\n');
    const versionField = `Version: ${this.version}`;

    const nonceField = `Nonce: ${this.nonce}`;

    const suffixArray = [uriField, versionField, nonceField];

    suffixArray.push(`Issued At: ${this.issuedAt}`);

    const suffix = suffixArray.join('\n');
    prefix = [prefix, this.statement].join('\n\n');
    if (this.statement) {
      prefix += '\n';
    }
    return [prefix, suffix].join('\n');
  }

  verify({ signature, nonce, time }: { signature: string; nonce?: string; time?: string }) {
    const message = this.prepareMessage();

    /** Check time or now */
    const checkTime = new Date(time || new Date());

    /** Message not expired */
    if (this.expirationTime) {
      const expirationDate = new Date(this.expirationTime);
      if (checkTime.getTime() >= expirationDate.getTime()) {
        throw new Error(SiwsErrorType.EXPIRED_MESSAGE);
      }
    }

    if (this.nonce !== nonce) {
      throw new Error(SiwsErrorType.NONCE_MISMATCH);
    }

    if (
      !nacl.sign.detached.verify(
        new TextEncoder().encode(message),
        bs58.decode(signature),
        bs58.decode(this.address),
      )
    )
      throw new Error(SiwsErrorType.INVALID_SIGNATURE);

    return Promise.resolve(this);
  }
}

import { z } from 'zod';

import {
  baseApiTaskSchema,
  baseDateTaskSchema,
  baseDiscordTaskSchema,
  baseFileTaskSchema,
  baseInvitesTaskSchema,
  baseNFTTaskSchema,
  baseNumberTaskSchema,
  baseOnChainTaskSchema,
  baseOpinionTaskSchema,
  basePollTaskSchema,
  baseQuizTaskSchema,
  baseRewardMethodAllSchema,
  baseRewardMethodFcfsSchema,
  baseRewardMethodRaffleSchema,
  baseRewardMethodTopSchema,
  baseTelegramTaskSchema,
  baseTextTaskSchema,
  baseTiktokTaskSchema,
  baseTokenTaskSchema,
  baseTweetReactTaskSchema,
  baseTweetTaskSchema,
  baseTwitterFollowTaskSchema,
  baseTwitterSpaceTaskSchema,
  baseUrlTaskSchema,
  baseVisitLinkTaskSchema,
  conditionOperatorSchema,
  conditionSchema,
  partnershipTaskSchema,
  positiveIntSchema,
  recurrenceSchema,
  rewardOtherSchema,
  rewardRoleOutputSchema,
  rewardTokenSchema,
  rewardTypeSchema,
  rewardXPSchema,
} from './common';

export const rewardNFTSchema = z.object({
  type: z.literal(rewardTypeSchema.enum.nft),
  maxSupply: positiveIntSchema,
  value: z.string().uuid(),
});

const baseRewardId = z.object({
  id: z.string().uuid(),
});

// "all" reward method has no id - it is not treated in the same way as special reward methods
export const baseRewardMethodWithoutAllSchema = z.discriminatedUnion('type', [
  baseRewardMethodFcfsSchema.merge(baseRewardId),
  baseRewardMethodRaffleSchema.merge(baseRewardId),
  baseRewardMethodTopSchema.merge(baseRewardId),
]);

export const baseRewardMethodWithIdSchema = z.discriminatedUnion('type', [
  ...baseRewardMethodWithoutAllSchema.options,
  baseRewardMethodAllSchema,
]);

export const augmentedRewardSchema = z
  .discriminatedUnion('type', [
    rewardXPSchema,
    rewardOtherSchema,
    rewardRoleOutputSchema,
    rewardNFTSchema,
    rewardTokenSchema,
  ])
  .and(
    z.object({
      method: baseRewardMethodWithIdSchema.optional(),
    }),
  );

export const taskSchema = z.discriminatedUnion('type', [
  partnershipTaskSchema,
  baseNFTTaskSchema,
  baseTokenTaskSchema,
  baseOnChainTaskSchema,
  baseApiTaskSchema,
  baseTextTaskSchema,
  baseDiscordTaskSchema,
  baseUrlTaskSchema,
  baseTelegramTaskSchema,
  baseQuizTaskSchema,
  baseInvitesTaskSchema,
  baseVisitLinkTaskSchema,
  baseFileTaskSchema,
  baseDateTaskSchema,
  baseNumberTaskSchema,
  basePollTaskSchema,
  baseOpinionTaskSchema,
  baseTwitterFollowTaskSchema,
  baseTwitterSpaceTaskSchema,
  baseTweetReactTaskSchema,
  baseTweetTaskSchema,
  baseTiktokTaskSchema,
]);

const baseId = z.object({
  id: z.string().uuid(),
});

export const baseQuestSchema = z.object({
  id: z.string().uuid(),
  categoryId: z.string().uuid().nullish(),
  name: z.string(),
  published: z.boolean(),
  archived: z.boolean(),
  recurrence: recurrenceSchema,
  conditionOperator: conditionOperatorSchema,
  retryAfter: z.number().nullable(),
  description: z.object({}).nullish(),
  conditions: z.array(conditionSchema),
  rewards: z.array(augmentedRewardSchema),
  tasks: z.array(z.intersection(baseId, taskSchema)),
  claimLimit: z.number().min(0).default(0),
  claimCounter: z.number().min(0).optional(),
  position: z.number().min(0).default(0),
});

export type QuestRewardMethod = z.infer<typeof baseRewardMethodWithIdSchema>;

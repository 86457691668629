const DISCORD_REGEX_VALIDATION =
  /^(?:http(?:s)?:\/\/)?(?:www\.)?discord\.(?:gg|com)(?:\/invite)?\/([a-zA-Z0-9_-]+)$/;

export const discordUrlValidation = (inviteLink: string) =>
  DISCORD_REGEX_VALIDATION.test(inviteLink);

export const getDiscordInvitationId = (inviteLink: string): false | string => {
  const res = DISCORD_REGEX_VALIDATION.exec(inviteLink);

  return Array.isArray(res) && res[1];
};

export const communityBase = {
  id: 'planet-zealy',
  name: 'Planet Zealy',
  description: 'Join our official Planet Zealy community 🌈 ',
  image:
    'https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/240d070a-cc94-49f2-b092-d3062f573ae0-profile.png',
  website: 'https://zealy.io',
  quests: '30',
  totalMembers: 30000,
  discord: 'https://discord.gg/',
  totalDiscordMembers: 42000,
  launchDate: null,
  subdomain: 'join',
  flag: false,
  featured: true,
  createdAt: '',
  updatedAt: '',
  visibility: 'public' as const,
  deleted: true,
  blockchain: '',
  certified: false,
};

export const COMMUNITY_CATEGORIES = [
  'all',
  'new',
  'featured',
  'trend',
  'upcoming',
  'nft',
  'art',
  'dao',
  'gaming',
  'music',
  'collectibles',
  'defi',
  'metaverse',
  'infrastructure',
  'education',
  'startup',
] as const;

export type CommunityCategory = (typeof COMMUNITY_CATEGORIES)[number];

export type Community = {
  id: string;
  name: string;
  subdomain: string;
  description?: string | null;
  flag: boolean;
  featured: boolean;
  logo?: string | null;
  image?: string | null;
  launchDate?: string | null;
  visibility: 'public' | 'private';
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  sector?: string | null;
  type?: string | null;
  website?: string | null;
  twitter?: string | null;
  discord?: string | null;
  opensea?: string | null;
  blockchain: string;
  goal?: string | null;
  referrer?: string | null;
  crmView?: string[] | null;
  productState?: 'pre' | 'post' | null;
  customerId?: string | null;
  teamSize?: number | null;
  twitterQuestEnabled?: boolean | null;
  whiteLabelConfig?: {
    css: string;
    logo: string;
  } | null;
  rank?: number | null;
  quests?: string | null;
  discordBotEnabled?: boolean | null;
  totalMembers?: number | null;
  twitterFollowers?: number | null;
  totalDiscordMembers?: number | null;
  minimumRequiredXp?: number | null;
  consumeInvitesEnabled?: boolean | null;
  billingEmail?: string;
  referralCode?: string | null;
  referrerId?: string | null;
  planId: string | null;
  requiredFields: {
    linkDiscord: boolean;
    linkTwitter: boolean;
    fillEmail: boolean;
    fillWallet: boolean;
  };
  v2?: boolean;
};

const OLD_BASE_URL = 'c' as const;
const NEW_BASE_URL = 'cw' as const;

export function getCommunityPath(community?: {
  subdomain: Community['subdomain'];
  v2?: Community['v2'];
}) {
  if (!community) {
    return '/';
  }
  const isFeatureOn = community.v2;
  const BASE_URL = isFeatureOn ? NEW_BASE_URL : OLD_BASE_URL;
  return `/${BASE_URL}/${community.subdomain}`;
}

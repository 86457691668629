import { z } from 'zod';

export const discordIntegrationTypeSchema = z.enum([
  'newQuest',
  'newClaim',
  'leaderboardSprintEnd',
  'dailySummary',
  'support',
]);

export type DiscordIntegrationType = z.infer<typeof discordIntegrationTypeSchema>;

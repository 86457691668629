import { z } from 'zod';

import { sprintSchema } from './getSprints';

export const patchSprintsV2InputSchema = sprintSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    includedQuestIds: true,
  })
  .extend({
    includedQuestIds: z.array(z.string()).min(1),
  })
  .partial();

export const patchSprintsInputSchema = sprintSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .partial();

const patchSprintsOutputSchema = z.array(sprintSchema);

export type PatchSprintsInput = z.infer<typeof patchSprintsInputSchema>;

export type PatchSprintsOutput = z.infer<typeof patchSprintsOutputSchema>;

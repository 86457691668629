/**
 * Gets the subdomain of the current community from the URL
 * @returns subdomain of current community or 'root' if no subdomain
 * @example https://zealy.io/c/my-community => 'my-community'
 * @example https://my-community.zealy.io => 'my-community'
 * @example https://zealy.io => 'root'
 */
export const getSubdomain = () => {
  if (typeof window === 'undefined') return 'root';

  /**
   * Get the subdomain from /c/{subdomain}/
   * @example https://zealy.io/c/my-community/questboard => 'my-community'
   * Regex explanation:
   * 1.\/c[w]?\/ => starts with /c/ or /cw/
   * 2. [\w-]+ => matches any alphanumeric string before the next /
   */
  const [, , subdomain] = /\/(c[w]?|embed\/c)\/([\w-]+)/.exec(window.location.pathname) || [];

  if (subdomain) {
    return subdomain;
  }

  /**
   * Split the URL by dots
   * @example https://my-community.zealy.io => ['my-community', 'zealy', 'io']
   */
  const domains = window.location.hostname.split('.');

  if (domains[0] === 'www' || domains[0] === 'app' || domains.length !== 3) {
    return 'root';
  }

  return domains[0];
};

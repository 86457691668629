import type { BlockchainNetwork } from '../communities';

export const blockchainToHexChainIdMapping = {
  'bnb-mainnet': '0x38',
  'linea-mainnet': '0xe708',
  'avalanche-mainnet': '0xa86a',
  'fantom-mainnet': '0xfa',
  'cronos-mainnet': '0x19',
  'palm-mainnet': '0x2a15c308d',
  'gnosis-mainnet': '0x64',
  'chiliz-mainnet': '0x15b38',
  'moonbeam-mainnet': '0x504',
} as Record<BlockchainNetwork, string>;

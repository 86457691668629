import { z } from 'zod';

import { basedTaskValueSchema } from './claimQuest';
import { augmentedTaskSchema } from './postQuest';

export const testClaimQuestInputSchema = z.object({
  tasks: z.array(augmentedTaskSchema),
  taskValues: z.array(
    basedTaskValueSchema.and(
      z.object({
        taskId: z.string().uuid(),
      }),
    ),
  ),
  id: z.literal('save-quest-to-generate').or(z.string().uuid()),
});

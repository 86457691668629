export const QUEST_REWARD_TYPES = ['xp', 'other', 'role', 'nft'] as const;
export const QUEST_TYPE = ['image', 'none', 'quiz', 'text', 'twitter'] as const;
export const QUEST_RECURRENCE = ['once', 'daily', 'weekly', 'monthly'] as const;
export const QUEST_RETRY_TIMESTAMP_RANGE = {
  IMMEDIATELY: 0,
  ONE_MINUTE: 60,
  FIVE_MINUTES: 300,
  THIRTY_MINUTES: 1800,
  ONE_HOUR: 3600,
  ONE_DAY: 86400,
  ONE_WEEK: 604800,
  ONE_MONTH: 2592000,
  NEVER: -1,
} as const;

export type Quest = {
  id: string;
  name: string;
  description: Record<string, unknown>;
  position: number;
  validationData: Record<string, unknown>;
  createdAt: Date;
  updatedAt: Date;
  submissionType: QuestType;
  published: boolean;
  deleted: boolean;
  unlocked?: boolean | 'never';
  reward: QuestReward[];
  canRetry?: boolean;
  retryIn?: string;
  inReview?: boolean;
  retryAfter: QuestRetryTimestampRange | null;
};

export type QuestType = (typeof QUEST_TYPE)[number];
export type QuestRewardType = (typeof QUEST_REWARD_TYPES)[number];
export type QuestRetryTimestampRange =
  (typeof QUEST_RETRY_TIMESTAMP_RANGE)[keyof typeof QUEST_RETRY_TIMESTAMP_RANGE];
export type QuestRecurrence = (typeof QUEST_RECURRENCE)[number];

type QuestRewardBuilder<
  T extends QuestRewardType,
  O = Omit<QuestReward, 'type'>,
  R = O & { type: T },
> = {
  [K in keyof R]: R[K];
};

export type QuestReward = {
  type: QuestRewardType;
  value: string | number;
  id?: string;
  image?: File[];
  imageUrl?: string;
  name?: string;
  symbol?: string;
  maxSupply?: number;
  smartContractAddress?: string;
  totalClaimed?: number;
  totalSupply?: number;
};

export type NFTQuestReward = QuestRewardBuilder<'nft'>;
